import { createSignal, type Component } from 'solid-js';
import { hubspotMarketingEmailConsent } from '~/components/actions';
import { t } from '~/libs/i18n/utils';
import { OneTimeAction, api } from '~/modules/api';
import { showAlert } from '~/utils';
import { CloseIcon } from '../../../components/icons';
import { Button, Checkbox, Popup } from '../../../components/ui';
import type {
  OneTimeActionExecutionContext,
  OneTimeActionRunner,
} from '../types';
import { completeAction } from './commons';

const [showModal, setShowModal] = createSignal(false);

export const action = OneTimeAction.ANSWER_MARKETING_EMAIL_CONSENT;

export const run = async () => {
  setShowModal(true);
  return false;
};

export const component: Component = (ctx: OneTimeActionExecutionContext) => {
  const [consent, updateConsentCheck] = createSignal(false);

  const closeModal = async () => setShowModal(false);

  const savePreference = async () => {
    try {
      if (consent() && ctx.user?.email) {
        await hubspotMarketingEmailConsent({
          email: ctx.user.email,
          onFailure: () => {
            throw new Error('Error sending marketing consent to Hubspot.');
          },
        });
      }

      await api.User.updateMarketingConsent({
        body: {
          marketingConsent: consent(),
        },
      }).then(r => {
        if (r.error) {
          throw new Error(
            'Error saving marketing email preference: ' + r.error.message,
          );
        }
        if (ctx.user) {
          completeAction('answerMarketingEmailConsent', ctx.user);
        }
      });
      setShowModal(false);
    } catch (err) {
      showAlert(t('common:alerts.error.preferences'), {
        type: 'error',
      });
      console.error(err);
    }
  };
  return (
    <Popup
      show={showModal() && !ctx.user?.isMinor}
      onClose={closeModal}
      position={{ sm: 'center', base: 'bottom' }}
    >
      <div class="relative sm:max-w-sm bg-white rounded-2xl p-6 py-9 space-y-6">
        <Button
          class="absolute size-6 top-4 right-4"
          variant="ghost"
          size="icon"
          onClick={closeModal}
        >
          <CloseIcon class="size-3" />
        </Button>

        <img
          class="w-64 mx-auto"
          src="/images/dreamflare-box.png"
          alt="DreamFlare Content Box"
        />

        <h3 class="df-font-beatrice font-bold text-2xl text-center text-black">
          {t('common:modals.emailConsent.welcome')}
        </h3>

        <Checkbox
          id="consent-checkbox"
          label={t('common:modals.emailConsent.consent')}
          aria-label="Consent Check"
          value="consent"
          initialCheck={consent()}
          onChange={evt => {
            const event = evt as unknown as { target: { checked: boolean } };
            updateConsentCheck(event.target.checked as boolean);
          }}
        />

        <Button
          class="mt-2"
          variant="primary"
          width="full"
          size="xl"
          onClick={savePreference}
        >
          {t('common:buttons.continue')}
        </Button>
      </div>
    </Popup>
  );
};

// NOTE: We normally do not use the default export, but we want to load runners dynamically
// so either we use the default export or we need to pick a name for an exported variable.
export default {
  action,
  component,
  run,
} satisfies OneTimeActionRunner;
